import { Fragment, useState } from "react";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { ThreadListV2 } from "./index";
import { type V2ThreadsLoader } from "~/routes/threads";
import ThreadsEditor from "~/components/threads/ThreadsEditor";
import { type ThreadsFeed } from "~/utils/leocache";
import SubscriptionsDropdown from "~/components/SubscriptionsDropdown";
import { getInfraSettings } from "~/utils/infra";
import { getActiveAccount } from "~/session.server";

export const meta: MetaFunction = () => [
  { title: "InLeo | Microblogging in Blockchain" },
  { property: "og:title", content: "InLeo | Microblogging in Blockchain" }
];

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccount = await getActiveAccount(request);

  let feed = "latest" as ThreadsFeed;

  if (activeAccount) {
    const { settings } = await getInfraSettings(activeAccount);

    if (settings.default_feed && settings.default_feed !== feed) {
      feed = settings.default_feed;
    }
  }

  let personalFeed = feed === "foryou" || feed === "following";

  if (personalFeed && !activeAccount) {
    personalFeed = false;
    feed = "latest";
  }
  return json(
    { activeAccount, feed },
    { headers: { "Cache-Control": "public, max-age=30" } }
  );
};

export default function Index() {
  const { activeAccount, feed } = useLoaderData() as V2ThreadsLoader;

  const [encryption, setEncryption] = useState(false);

  return (
    <Fragment>
      {activeAccount !== null && (
        <ThreadsEditor
          mainThreadEditor={true}
          encryption={encryption}
          setEncryption={setEncryption}
        />
      )}

      <div className="flex flex-row items-center justify-between py-3 px-3 border-b border-pri dark:border-pri-d">
        <span className="text-sm" />

        <SubscriptionsDropdown />
      </div>

      <ThreadListV2 activeAccount={activeAccount} feedType={feed} />
    </Fragment>
  );
}

